import React, { useState, useEffect, useRef } from "react";
import { graphql, StaticQuery } from "gatsby";
import SEO from "../components/SEO";
import styled from "styled-components";
import Breadscrum from "../components/Breadscrum";
import { useMediaQuery } from 'react-responsive';
import ProgressBar from "../components/parte/ProgressBar";
import axios from "axios";
import ReactMarkdown from 'react-markdown';
import 'react-photoswipe/lib/photoswipe.css';
import {PhotoSwipeGallery} from 'react-photoswipe';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import KomentarAdd from "../components/parte/KomentarAdd";
import KomentarBox from "../components/parte/KomentarBox";
import ShareIcon from "../assets/img/share.svg";
import DonioLogo from "../assets/img/donioLogo.svg";
import Sipka from "../assets/img/sipkaRightW.svg";
import SipkaB from "../assets/img/sipkaRightB.svg";
import FacebookLogo from "../assets/img/facebookLogo.svg";
import TwitterLogo from "../assets/img/twitterLogo.svg";
import EmailLogo from "../assets/img/emailLogo.svg";
import WhatsAppLogo from "../assets/img/whatsAppLogo.svg";
import LoadingImg from "../assets/img/loading.svg";
import ParteModal from "../components/parte/parteModal";
import EditAuthModal from "../components/parte/EditAuthModal";
import Loading from "../assets/img/loadingTransparent.svg";



const ParteInner = styled.div`
  overflow-x: hidden;
  background: #fdfdfd;

  .breadscrumSection {
    max-width: 1150px;
    margin: 40px auto;
    width: calc(100% - 100px);

    @media (max-width: 850px) {
      margin: 20px auto;
      width: calc(100% - 50px);
      max-width: 450px;
    }
  }

  .obsahWrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-top: 50px;

    @media (max-width: 850px) {
      margin-top: 0px;
    }

    .obsahInner {
      min-height: calc(${props => props.minHeight}px);

      @media (max-width: 1000px) {
        min-height: 0;
        margin-bottom: 80px;
      }

      @media (max-width: 850px) {
        margin-bottom: 60px;
      }

      .basicInfo {
        width: calc(100% - 100px);
        max-width: 1150px;
        margin: 0 auto;

        @media (max-width: 1250px) {
          width: calc(100% - 480px);
          margin-left: 50px;
        }

        @media (max-width: 1000px) {
          width: calc(100% - 100px);
          margin: 0 auto;
        }

        @media (max-width: 850px) {
          width: calc(100% - 50px);
          max-width: 450px;
        }
      }

      .mobileShareButton {
        display: none;

        @media (max-width: 850px) {
          display: block;
        }

        button {
          background: none;
          border: 0;
          z-index: 11;
          position: absolute;
          top: 30px;
          right: calc((100% - 450px)/2);

          @media (max-width: 530px) {
            top: 30px;
            right: 25px;
          }

          img {
            display: block;
            margin-left: auto;
            margin-bottom: 10px;
          }

          span {
            display: block;
            font-family: Visuelt-Medium;
            font-size: 16px;
            color: #000000;
          }
        }

      }
      
      .mobileShare {
        display: none;

        @media (max-width: 850px) {
          display: block;
          background: #F8F6EF;
          width: 100%;
          height: 120px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
        }
      }

      .profile {
        display: flex;
        align-items: center;
        margin-bottom: 70px;
        max-width: 630px;
        z-index: 1;
        position: relative;

        @media (max-width: 1000px) {
          max-width: none;
        }

        @media (max-width: 850px) {
          padding-top: 30px;
          display: block;
          margin-bottom: 30px;
        }

        .obrazek {
          border-radius: 100%;
          margin-right: 50px;
          width: 200px;
          height: 200px;
          position: relative;
          overflow: hidden;

          @media (max-width: 1250px) {
            margin-right: 35px;
          }

          @media (max-width: 850px) {
            width: 180px;
            height: 180px;
            margin-bottom: 30px;
          }
        }

        .text {
          div {
            margin-bottom: 15px;

            @media (max-width: 850px) {
              margin-bottom: 12px;
            }

            h1 {
              font-family: Visuelt-Medium;
              font-size: 24px;
              color: #000000;
              line-height: 25px;
              display: inline-block;
            }

            span {
              font-family: Visuelt-Regular;
              font-size: 16px;
              color: #7C7C7C;
              text-align: center;
              line-height: 25px;
              margin-left: 10px;
            }
          }

          p {
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #7C7C7C;
            line-height: 25px;
          }
        }
      }

      .textSection {
        max-width: 630px;
        margin-bottom: 80px;

        @media (max-width: 1000px) {
          max-width: none;
        }

        @media (max-width: 850px) {
          margin-bottom: 30px;
        }

        p {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #000000;
          line-height: 25px;
          margin-bottom: 25px;
        }
      }

      .grayZone {
        background: #f4f3f0;
        width: 100%;
        padding-top: 60px;
        padding-bottom: 80px;

        @media (max-width: 850px) {
          padding-top: 40px;
          padding-bottom: 40px;
          margin-top: 40px;
        }

        .grayZoneInner {
          width: calc(100% - 100px);
          max-width: 1150px;
          margin: 0 auto;

          @media (max-width: 1250px) {
            width: calc(100% - 480px);
            margin-left: 50px;
          }

          @media (max-width: 1000px) {
            width: calc(100% - 100px);
            margin: 0 auto;
          }

          @media (max-width: 850px) {
            width: calc(100% - 50px);
          }
        }

        .galerie {
          max-width: 630px;
          margin-bottom: 70px;

          @media (max-width: 1000px) {
            max-width: none;
          }

          @media (max-width: 850px) {
            margin-bottom: 60px;
            max-width: 450px;
            margin-left: auto;
            margin-right: auto;
          }

          h2 {
            font-family: Visuelt-Medium;
            font-size: 22px;
            color: #000000;
            line-height: 25px;
            margin-bottom: 30px;

            @media (max-width: 850px) {
              font-size: 20px;
              line-height: 28px;
            }
          }

          .galerieInner {
            .pswp-thumbnails {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              gap: 30px 30px;

              @media (max-width: 850px) {
                grid-template-columns: 1fr 1fr;
                gap: 15px 15px;
              }
            } 

            .addPicture {
              background: #FFFFFF;
              border-radius: 5px;
              border: 0;
              
              p {
                margin-top: 15px;
                font-family: Visuelt-Medium;
                font-size: 16px;
                color: #515151;
                text-align: center;
              }
            }
          }
        }
      }

      .zedVzpominek {
        max-width: 630px;

        @media (max-width: 1000px) {
          max-width: none;
        }

        @media (max-width: 850px) {
          max-width: 450px;
          margin: 0 auto;
        }

        h2 {
          font-family: Visuelt-Medium;
          font-size: 22px;
          color: #000000;
          line-height: 25px;
          margin-bottom: 30px;

          @media (max-width: 850px) {
            font-size: 20px;
            line-height: 28px;
          }
        }

        .zedVzpominekInner {
          .posts {
            
          }
        } 
      }
    }

    .rightPanel {
      max-width: 370px;
      position: absolute;
      top: 0;
      right: calc((100% - 1150px)/2);
      padding-bottom: 100px;

      @media (max-width: 1250px) {
        right: 50px;
        max-width: 340px;
      }

      @media (max-width: 1000px) {
        position: static;
        max-width: none;
        margin: 0 auto;
        width: calc(100% - 100px);
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px 50px;
      }

      @media (max-width: 850px) {
        display: block;
        width: calc(100% - 50px);
      }
    }
  }

  .box {
    background: #FFFFFF;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.08);
    border-radius: 10px;
    border: 1px solid #f1f1f1;





    padding: 25px;
    margin-bottom: 40px;

    @media (max-width: 1000px) {
      height: fit-content;
      margin-bottom: 0px;
    }

    @media (max-width: 850px) {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;
      max-width: 400px;
    }

    @media (max-width: 400px) {
      border: 0;
      padding: 0;
      margin-bottom: 60px;
    }

    h2 {
      font-family: Visuelt-Medium;
      font-size: 22px;
      color: #000000;
      line-height: 32px;

      @media (max-width: 850px) {
        font-size: 20px;
        line-height: 28px;
      }

      @media (max-width: 400px) {
        border-top: 1px solid #D8D8D8;
        padding-top: 15px;
      }
    }

    .boxInner {
      border-top: 1px solid #D8D8D8;
      padding-top: 30px;
      margin-top: 15px;
    }
  }

  .donioBox {
    .loadingDonio {
      display: block;
      margin: 0 auto;
      width: 100px;
    }

    .donioPic {
      height: 140px;
      overflow: hidden;
      border-radius: 5px;
    }

    h3 {
      font-family: NY-Medium;
      font-size: 20px;
      color: #000000;
      line-height: 27px;
      margin: 25px auto;
    }

    .progressBar {
      margin-bottom: 15px;
    }

    .textWrapper {
      position: relative;

      .procenta {
        right: 0;
        color: #e66d45;
        font-family: Visuelt-Bold;
        position: absolute;
        top: 0;
        font-size: 18px;
      }

      .zelenaProcenta {
        color: #6ac362;
      }
    }

    .vybrano {
      font-family: Visuelt-Bold;
      font-size: 18px;
      color: #000000;
    }

    .odDarcu {
      font-family: Visuelt-Medium;
      font-size: 16px;
      color: #9E9E9E;
      margin-top: 7px;
      margin-bottom: 30px;
    }

    a {
      background: #e66e3b;
      width: 100%;
      padding: 20px 0;
      font-family: Visuelt-Medium;
      display: block;
      text-decoration: none;
      border-radius: 5px;
      font-size: 18px;
      color: #FFFFFF;
      text-align: center;

      &:hover {
        background: #ce5b2a;
      }
    }

    .donioBot {
      text-align: center;
      
      p {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000000;
        text-align: center;
        text-align: center;
        margin: 20px auto;
        line-height: 25px;
        margin-bottom: 10px;
      }

      img {
        margin: 0 auto;
      }
    }
  }

  .funeralInfo {
    .boxInner {
      p {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000000;
        line-height: 25px;
      }

      .adresa {
        p {
          margin-top: 20px;
          margin-bottom: 20px;

          span {
            font-family: Visuelt-Bold;
          }
        }

        iframe {
          width: 100%;
          height: 150px;
          border-radius: 5px;
        }
      }
    }
  }

  .shareBox {
    .boxInner {
      .label {
        font-family: Visuelt-Bold;
        text-transform: uppercase;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        margin-bottom: 15px;
      }

      .copyInput {
        margin-bottom: 25px;
        position: relative;
        border-radius: 5px;

        div {
          background-image: linear-gradient(90deg, rgba(242,242,242,0.00) 0%, #F2F2F2 15%);
          width: 130px;
          height: 100%;
          border-radius: 5px;
          position: absolute;
          top: 0;
          right: 0;

          button {
            background: #535353;
            border-radius: 5px;
            font-family: Visuelt-Medium;
            font-size: 14px;
            color: #FFFFFF;
            border: 0;
            text-align: center;
            margin-left: auto;
            display: block;
            position: absolute;
            top: 10px;
            right: 15px;
            padding: 6px 10px;

            &:hover {
              cursor: pointer;
              background: #2f2f2f;
            }

            &:active {
              cursor: pointer;
              background: black;
            }
          }
        }

        input {
          background: #F2F2F2;
          border: 0;
          width: calc(100% - 30px - 80px);
          padding: 15px;
          padding-right: 95px;
          border-radius: 5px;
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #000000;
        }
      }

      .socials {
        button {
          display: block;
          width: calc(100%);
          margin-bottom: 15px;
          position: relative;
          border-radius: 5px;

          &:last-child {
            margin-bottom: 0;
          }

          .sockyInner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 0;
            border-radius: 5px;
            padding: 15px;
            width: calc(100% - 30px);
            text-align: left;
            height: 22px;
          }

          div {
            display: flex;
            align-items: center;

            span {
              font-family: Visuelt-Medium;
              font-size: 16px;
              color: #FFFFFF;
              margin-bottom: -2px;
              margin-left: 15px;
              position: absolute;
              left: 40px;
            }
          }
          
        }

        .facebook {
          background: #007afa;

          &:hover {
            cursor: pointer;
            background: #006ada;
          }
        }

        .twitter {
          background: #00a4f9;

          &:hover {
            cursor: pointer;
            background: #0096e4;
          }
        }

        .whatsapp {
          background: #25D366;

          &:hover {
            cursor: pointer;
            background: #1db556;
          }
        }

        .email {
          background: rgb(255,238,98);

          &:hover {
            cursor: pointer;
            background: #EFD83E;
          }

          span {
            color: black;
          }
        }
      }
    }
  }

  .upravitParte {

    @media (max-width: 850px) {
      margin: 0 auto;
      width: calc(100%);
      max-width: 450px;
    }

    button {
      font-family: Visuelt-Medium;
      font-size: 16px;
      color: #000000;
      border: 0;
      background: none;
      text-decoration: underline;
    
      &:hover {
        cursor: pointer;
      }
    }
  }

  .shareModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.50);
    z-index: 100;

    h2 {
      @media (max-width: 400px) {
        border-top: none;
      }
    }

    .shareBox {
      max-width: 370px;
      width: calc(100% - 50px);
      margin: 0 auto;
      margin-top: 100px;
      position: relative;
      overflow: scroll;
      max-height: calc(100vh - 250px);


      @media (max-width: 580px) {
        padding: 20px;
        width: calc(100% - 80px);
        margin-top: 50px;
        max-height: calc(100vh - 60px);
        overflow: scroll;
      }

      @media (max-width: 400px) {
        padding: 20px;
        width: calc(100% - 60px);
        margin-top: 10px !important;
        max-height: calc(100vh - 100px) !important;
      }

      @media (max-height: 770px) {
        margin-top: 50px;
        max-height: calc(100vh - 150px);
      }

      @media (max-height: 400px) {
        margin-top: 25px;
        max-height: calc(100vh - 100px);
      }

      .shareBoxInner {
        min-height: fit-content;
      }
    }

    .zavritButton {
      font-family: Visuelt-Medium;
      text-decoration: underline;
      background: none;
      border: 0;
      display: block;
      margin: 0 auto;
      font-size: 16px;
      margin-top: 25px;
      color: #000000;
      text-align: center;

      &:hover {
        cursor: pointer;
      }
    }

    .zavritButtonPrvni  {
      margin-left: 0;
      text-decoration: none;
      padding: 7px 15px;
      border-radius: 5px;
      background: #f2f2f2;
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  .loadingScreen {
    background: rgb(241, 242, 243);
    min-height: calc(100vh - 65px);

    img {
      margin: 0 auto;
      padding-top: 30px;
      display: block;
    }
  }
`;

const ObrazekGalleryWrapper = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  height: 120px;

  @media (max-width: 400px) {
    height: 90px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const ObrazekGallery = styled.img`
  background: url(${props => props.item.thumbnail});
  background-position: center;
  background-repeat: no-repeat;
  min-width: calc(100% + 2px);
  min-height: calc(100% + 2px);
  background-size: cover;
  position: absolute;
  left: -1px;
  top: -1px;
`;

const ObrazekStyled = styled.div`
  background: url(${props => props.profilePicture});
  background-size: cover;
  background-position: center;
`;

function datesToAge(deathDateO, birthDateO) {
  var deathDate = Date.parse(deathDateO);
  var birthDate = Date.parse(birthDateO);
  return (Math.round((deathDate - birthDate)/1000/60/60/24/365.25));
}

function formatedDate(dateO) {
  var date = Date.parse(dateO);
  date = new Date(date);
  return (date.toLocaleDateString("cs-CZ", {dateStyle: "long"}));
}


const DonioBublina = ({ donioData }) => {

  if (donioData) {
    let progress = 100;
    let zelenaProcenta = false;
  
    if (donioData.maxAmount) {
      progress = (donioData.data.currentAmount / donioData.maxAmount) * 100;

      if (progress >= 100) {
        zelenaProcenta = true;
      }
    }

    return (
      <div className="box donioBox">
        <h2>Přispějte na tuto sbírku jako památku na vašeho blízkého</h2>
        <div className="boxInner">
          <div className="donioPic">
            <img src={donioData.imageUrl} alt={donioData.data.name} />
          </div>
          <h3>{donioData.data.name}</h3>
          <ProgressBar completed={progress} />
  
          <div className="textWrapper">
            {(donioData.maxAmount) &&
              <div className={`procenta ${(zelenaProcenta) && "zelenaProcenta"}`}>{Math.round(progress)}%</div>
            }
            <div className="vybrano">Vybráno {parseFloat(donioData.data.currentAmount).toLocaleString("cs")} Kč</div>
            <div className="odDarcu">Od {parseFloat(donioData.data.donationsCount).toLocaleString("cs")} dárců</div>
          </div>
  
          
          <a target="_blank" href={donioData.url}>Přispět na dobrou věc</a>
          <div className="donioBot">
            <p>Předáme 100% vašeho daru</p>
            <img src={DonioLogo} alt="Donio Logo" />
          </div>
        </div>
      </div>
    );
  }
  
  
  else {
    return (
      <div className="box donioBox">
        <img className="loadingDonio" src={Loading} alt="Načítání..." />
      </div>
    );
  }
}

const Parte = ({ slug, shareModal }) => {
  const [modalOpen, setModalOpen] = useState(shareModal);
  const [parteData, setParteData] = useState();
  const [donioData, setDonioData] = useState();
  const [isAuthModal, setAuthModal] = useState(false);
  const [galleryItems, setGalleryItems] = useState([]);
  const [commentItems, setCommentItems] = useState([]);

  const [height, setHeight] = useState(0);
  const rightPanel = useRef(null);

  if (shareModal) {
    window.history.replaceState(null, null, `/prt/${slug}`);
  }


  const getThumbnailContent = (item) => {
    return (
      <ObrazekGalleryWrapper>      
        <ObrazekGallery item={item} />
      </ObrazekGalleryWrapper>
    );
  }
  

  useEffect(() => {
    let backendUrl = "";

    if (process.env.NODE_ENV === 'development') {
      backendUrl = 'http://localhost:1337'
    }
    
    if (process.env.NODE_ENV === 'production') {
      backendUrl = 'https://goodbye-backend.herokuapp.com'
    }


    axios.get(`${backendUrl}/partes/${slug}`).then((res) => {

      if (res.data.isPublished === true) {
        setParteData(res.data);

        if (res.data.donioId !== null && res.data.donioId !== 0) {
          if (typeof res.data.donioId !== "undefined") {
            axios.get(`${backendUrl}/donios/${res.data.donioId}`).then((res) => {
              setDonioData(res.data);
              setHeight(rightPanel.current.clientHeight);
            });
          }
        }


        





        let items = [];

        res.data.gallery.forEach(element => {

          let fotka = element.url;

          if (typeof element.formats !== "undefined") {
            if (typeof element.formats.small !== "undefined") {
              fotka = element.formats.small.url;
            }

            else {
              fotka = element.formats.thumbnail.url;
            }
          }


          let item = {
            src: element.url,
            thumbnail: fotka,
            w: element.width,
            h: element.height,
            title: 'Obrázek'
          };

          items.push(item)
        });

        setGalleryItems(items);


        axios.get(`${backendUrl}/comments/${slug}`).then((res) => {

          let komentare = res.data;

          komentare.sort(function(a, b) {
            var keyA = new Date(a.time);
            var keyB = new Date(b.time);
            // Compare the 2 dates
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
          });

          setCommentItems(komentare);
          setHeight(rightPanel.current.clientHeight);
        });
      }
      
      else {

      }
    });
  }, []);

  let pocitadlo = 0;

  const isMin400 = useMediaQuery({
    query: '(max-width: 400px)'
  });


  return (
    <StaticQuery
      query = {graphql`
        query {
          profilePicBig: file(relativePath: { eq: "parteSamplePic.png" }) {
            childImageSharp {
              fixed(width: 200) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
      
          profilePicSmall: file(relativePath: { eq: "parteSamplePic.png" }) {
            childImageSharp {
              fixed(width: 180) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
      
          sampleImage: file(relativePath: { eq: "sampleImage.png" }) {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
      
          donioSample: file(relativePath: { eq: "donioSample.png" }) {
            childImageSharp {
              fluid(maxWidth: 320) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <ParteInner minHeight={height}>
            {(parteData) ? <>
              <SEO
                title={`${parteData.name} | Parte online`}
                description={parteData.description}
                image={encodeURI(`https://og-img-final-styvekdrtic.vercel.app/ahoj.jpg?zesnuly=${parteData.name}&datum=${formatedDate(parteData.birthDate) + " - " + formatedDate(parteData.deathDate)}&image=${parteData.profilePicture.url}`)}
              />
              <section className="breadscrumSection">
                <Breadscrum
                  visible={true}
                  stranky={[
                    {name: "Domů", url: "/"},
                    {name: "Pohřební služba", url: "/pohrebni-sluzba/"},
                    {name: "Online parte", url: "/parte/"},
                    {name: "Poslední vytvořené", url: "/parte/posledni-vytvorene/"},
                    {name: parteData.name, url: `/prt/${parteData.url}/`},
                  ]}
                />
              </section>

              {(modalOpen) ?
                <div className="shareModal">
                  <div className="box shareBox">
                    <div className="shareBoxInner">
                      {isMin400 && <button className="zavritButton zavritButtonPrvni" onClick={() => setModalOpen(false)}>Zavřít</button>}
                      <h2>Sdílejte parte mezi své blízké</h2>
                      <div className="boxInner">
                        <div className="label">Odkaz</div>
                        <div className="copyInput">
                          <div>
                            <button onClick={() => {navigator.clipboard.writeText(`https://goodbye.cz/prt/${parteData.slug}`)}}>Kopírovat</button>
                          </div>
                          <input type="text" value={`https://goodbye.cz/prt/${parteData.slug}`} readOnly />
                        </div>
                        <div className="label">Sociální sítě</div>
                        <div className="socials">
                        
                          <FacebookShareButton url={`https://goodbye.cz/prt/${parteData.slug}`}>
                            <div className="sockyInner facebook">
                              <div>
                                <img src={FacebookLogo} alt="" className="logo" />
                                <span>Sdílet na Facebooku</span>
                              </div>
                              <img src={Sipka} alt="" className="sipka" />
                            </div>
                          </FacebookShareButton>

                          <TwitterShareButton title={parteData.name} url={`https://goodbye.cz/prt/${parteData.slug}`}>
                            <div className="sockyInner twitter">
                              <div>
                                <img src={TwitterLogo} alt="" className="logo" />
                                <span>Sdílet na Twitteru</span>
                              </div>
                              <img src={Sipka} alt="" className="sipka" />
                            </div>
                          </TwitterShareButton>

                          <WhatsappShareButton title={parteData.name} url={`https://goodbye.cz/prt/${parteData.slug}`}>
                            <div className="sockyInner whatsapp">
                              <div>
                                <img src={WhatsAppLogo} alt="" className="logo" />
                                <span>Sdílet na WhatsApp</span>
                              </div>
                              <img src={Sipka} alt="" className="sipka" />
                            </div>
                          </WhatsappShareButton>

                          <EmailShareButton url={`https://goodbye.cz/prt/${parteData.slug}`}>
                            <div className="sockyInner email">
                              <div>
                                <img src={EmailLogo} alt="" className="logo" />
                                <span>Pošlete odkaz e-mailem</span>
                              </div>
                              <img src={SipkaB} alt="" className="sipka" />
                            </div>
                          </EmailShareButton>

                        </div>
                      </div>
                      <button className="zavritButton" onClick={() => setModalOpen(false)}>Zavřít</button>
                    </div>
                  </div>
                </div>
              : null}


              {(isAuthModal) && 
                <ParteModal heading="Upravit parte" setIsVisible={setAuthModal}>
                  <EditAuthModal parteData={parteData} />
                </ParteModal>
              }

              <section className="obsahWrapper">
                <div className="obsahInner">
                  <div className="basicInfo">
                    <div className="mobileShareButton">
                      <button onClick={() => setModalOpen(true)}>
                        <img src={ShareIcon} alt="Sdílet" />
                        <span>Sdílet</span>
                      </button>
                    </div>
                    <div className="mobileShare" />
                    <div className="profile">

                      {(parteData.profilePicture.formats.large) ?
                        <ObrazekStyled profilePicture={parteData.profilePicture.formats.large.url} className="obrazek" />
                      :
                        <ObrazekStyled profilePicture={parteData.profilePicture.url} className="obrazek" />
                      }

                      <div className="text">
                        <div>
                          <h1>{parteData.name}</h1>
                          <span>{datesToAge(parteData.deathDate, parteData.birthDate)} let</span>
                        </div>
                        <p>{formatedDate(parteData.birthDate)} - {formatedDate(parteData.deathDate)}</p>
                      </div>
                    </div>

                    <div className="textSection">
                      <ReactMarkdown source={parteData.description} />
                    </div>
                  </div>

                  <div className="grayZone">
                    <div className="grayZoneInner">
                      {(galleryItems.length > 0) &&
                        <div className="galerie">

                          <h2>Galerie</h2>
                          <div className="galerieInner">

                            <PhotoSwipeGallery items={galleryItems} thumbnailContent={getThumbnailContent}/>

                            {/* <button className="addPicture">
                              <img src={PlusIcon} alt="Přidat více fotek" />
                              <p>Přidat více fotek</p>
                            </button> */}
                          </div>
                        </div>
                      }

                      <div className="zedVzpominek">
                        <h2>Zeď vzpomínek</h2>
                        <div className="zedVzpominekInner">

                          <KomentarAdd slug={parteData.slug} />

                          <div className="posts">

                            {commentItems.map((el) => {
                              pocitadlo++;

                              let today = Date.now();
                              let datum = Date.parse(el.time);

                              let rozdil = today - datum;

                              let pocetHodin = Math.floor(rozdil / 3600000);
                              let casoString = "";
                              datum = new Date(datum);


                              if (pocetHodin < 24) {
                                casoString = `(před ${pocetHodin}h)`;
                              }

                              else {
                                casoString = `(${datum.toLocaleDateString("cs-CZ", {dateStyle: "long"})})`;
                              }

                              return (
                                <KomentarBox password={el.password} key={pocitadlo} id={el.id} text={el.text} name={el.name} casoString={casoString} slug={slug} />
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div ref={rightPanel} className="rightPanel">

                  {(parteData.donioId !== null && parteData.donioId !== 0) && <>
                    {(typeof parteData.donioId !== "undefined") &&
                      <DonioBublina donioData={donioData} />
                    }
                  </>}

                  {(parteData.funeralDescription) &&
                    <div className="box funeralInfo">
                      <h2>Informace o pohřbu</h2>
                      <div className="boxInner">
                        <p>{parteData.funeralDescription}</p>

                        {(parteData.funeralAddress) &&
                          <div className="adresa">
                            <p><span>Adresa:</span> {parteData.funeralAddress}</p>
                            <iframe
                              frameBorder="0"
                              title={parteData.funeralAddress}
                              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDR-wPIwbrQ2srKQ5FYSRXXCxdbPgAFhS4&q=${parteData.funeralAddress}`}>
                            </iframe>
                          </div>
                        }
                      </div>
                    </div>
                  }


                  <div className="box shareBox">
                    <h2>Sdílejte parte mezi své blízké</h2>
                    <div className="boxInner">
                      <div className="label">Odkaz</div>
                      <div className="copyInput">
                        <div>
                          <button onClick={() => {navigator.clipboard.writeText(`https://goodbye.cz/prt/${parteData.slug}`)}}>Kopírovat</button>
                        </div>
                        <input type="text" value={`https://goodbye.cz/prt/${parteData.slug}`} readOnly />
                      </div>
                      <div className="label">Sociální sítě</div>
                      <div className="socials">
                      
                        <FacebookShareButton url={`https://goodbye.cz/prt/${parteData.slug}`}>
                          <div className="sockyInner facebook">
                            <div>
                              <img src={FacebookLogo} alt="" className="logo" />
                              <span>Sdílet na Facebooku</span>
                            </div>
                            <img src={Sipka} alt="" className="sipka" />
                          </div>
                        </FacebookShareButton>

                        <TwitterShareButton title={parteData.name} url={`https://goodbye.cz/prt/${parteData.slug}`}>
                          <div className="sockyInner twitter">
                            <div>
                              <img src={TwitterLogo} alt="" className="logo" />
                              <span>Sdílet na Twitteru</span>
                            </div>
                            <img src={Sipka} alt="" className="sipka" />
                          </div>
                        </TwitterShareButton>

                        <WhatsappShareButton title={parteData.name} url={`https://goodbye.cz/prt/${parteData.slug}`}>
                          <div className="sockyInner whatsapp">
                            <div>
                              <img src={WhatsAppLogo} alt="" className="logo" />
                              <span>Sdílet na WhatsApp</span>
                            </div>
                            <img src={Sipka} alt="" className="sipka" />
                          </div>
                        </WhatsappShareButton>

                        <EmailShareButton url={`https://goodbye.cz/prt/${parteData.slug}`}>
                          <div className="sockyInner email">
                            <div>
                              <img src={EmailLogo} alt="" className="logo" />
                              <span>Pošlete odkaz e-mailem</span>
                            </div>
                            <img src={SipkaB} alt="" className="sipka" />
                          </div>
                        </EmailShareButton>

                      </div>
                    </div>
                  </div>
                  <div className="upravitParte">
                    <button onClick={() => setAuthModal(true)}>Upravit parte</button>
                  </div>
                </div>
              </section>
            </>
            : 
            <div className="loadingScreen">
              <img src={LoadingImg} alt="Načítání..." />
            </div>
            }
          </ParteInner>
        </>
      )}
    />
  );
}


export default Parte;
