import React, { useState } from "react";
import styled from "styled-components";
import 'swiper/swiper-bundle.min.css';
import CheckCircle from "../../assets/img/success.svg";
import axios from "axios";
import ReactMarkdown from 'react-markdown';
import Sha1 from "../../components/hash.js";




const KomentarInner = styled.section`
  .post {
    background: #FFFFFF;
    border: 1px solid #C8C8C8;
    border-radius: 5px;
    padding: 30px;
    margin-top: 20px;

    @media (max-width: 850px) {
      padding: 20px;
    }

    p {
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #000000;
      line-height: 25px;
    }

    input {
      display: block;
      width: calc(100% - 24px);
      max-width: 300px;
      background: #FFFFFF;
      border: 2px solid #D2D2D2;
      border-radius: 5px;
      padding: 10px;
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #000;
      line-height: 25px;
      margin-top: 30px;

      &:focus {
        border: 2px solid #566FEF;
      }
    }

    .errorMsg {
      color: #FF6060;
      font-family: Visuelt-Regular;
      font-size: 16px;
      margin-top: 10px;
    }

    .tlacitka {
      margin-top: 30px;
  
      .tlacitko {
        display: inline-block;
        font-family: Visuelt-Medium;
        font-size: 16px;
        text-align: center;
        color: #000;
        background: #efefef;
        border: 0;
        padding: 10px 0;
        width: 120px;
        border-radius: 5px;
        margin: 0;
        height: 45px;

        &:hover {
          cursor: pointer;
        }

        &:first-child {
          background: #FF6060;
          color: #FFFFFF;
          margin-right: 20px;
        }
      }
    }

    .bot {
      border-top: 1px solid #D8D8D8;
      margin-top: 15px;
      padding-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 850px) {
        display: block;
      }

      .jmeno {
        font-family: Visuelt-Medium;
        font-size: 16px;
        color: #000000;
        line-height: 25px;

        span {
          font-family: Visuelt-Regular;
          color: #939393;
        }
      }

      .buttons {
        @media (max-width: 850px) {
          margin-top: 5px;
        }

        button {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #B0B0B0;
          text-align: right;
          line-height: 25px;
          text-decoration: underline;
          border: 0;
          background: 0;
          padding: 0;
          margin-left: 20px;
          cursor: pointer;

          @media (max-width: 850px) {
            margin: 0;
            margin-right: 10px;
          }
        }
      }
    }

    .pridano {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        margin-right: 20px;
      }

      span {
        font-family: Visuelt-Medium;
        font-size: 18px;
        color: #000;
      }
    }
  }
`;

const KomentarAdd = ({ text, name, casoString, id, slug, password }) => {
  const [phase, setPhase] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplayed, setIsDisplayed] = useState(true);
  const [pass, setPass] = useState("");
  const [errorText, setErrorText] = useState("");
  
  let backendUrl = "";

  if (process.env.NODE_ENV === 'development') {
    backendUrl = 'http://localhost:1337'
  }
  
  if (process.env.NODE_ENV === 'production') {
    backendUrl = 'https://goodbye-backend.herokuapp.com'
  }


  const nahlasit = () => {
    setIsLoading(true);


    const emailZprava = {
      to: 'podpora@goodbye.cz',
      from: 'podpora@goodbye.cz',
      subject: 'Nahlášený komentář',
      text: `
        <div>
          <h1>Nahlášený komentář</h1>
          <ul>
            <li>Text: ${text}</li>
            <li>Name: ${name}</li>
            <li>casoString: ${casoString}</li>
            <li>ID: ${id}</li>
            <li>Slug: ${slug}</li>
          </ul>
        </div>
      `,

      html: `
        <div>
          <h1>Nahlášený komentář</h1>
          <ul>
            <li>Text: ${text}</li>
            <li>Name: ${name}</li>
            <li>casoString: ${casoString}</li>
            <li>ID: ${id}</li>
            <li>Slug: ${slug}</li>
          </ul>
        </div>
      `,
    }


    // <table>
    //   <tr>
    //     <th>Company</th>
    //     <th>Contact</th>
    //     <th>Country</th>
    //   </tr>
    //   <tr>
    //     <td>Alfreds Futterkiste</td>
    //     <td>Maria Anders</td>
    //     <td>Germany</td>
    //   </tr>
    //   <tr>
    //     <td>Centro comercial Moctezuma</td>
    //     <td>Francisco Chang</td>
    //     <td>Mexico</td>
    //   </tr>
    // </table>


    // axios.post(`https://form------spree.io/f/xdopbwgv`, { text, name, casoString, id, slug })
    // .then(res => {
    //   if (res.status === 200) {
    //     setPhase(5);
    //     setIsLoading(false);
    //   }
    // });

    axios.post(`${backendUrl}/auth/local`, {
      identifier: 'app@goodbye.cz',
      password: 'Good.Bye123',
    }).then((res) => {
      axios.post(`${backendUrl}/poslimail`, emailZprava, {
        headers: {
          "Authorization": `Bearer ${res.data.jwt}`,
          "Content-Type": "application/json"
        },
      })
      .then(res => {
        if (res.status === 200) {
          setPhase(5);
          setIsLoading(false);
        }
      });
    });
  }

  const smazat = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (pass) {
      
      if (Sha1.hash(pass, {}) === password) {
        setErrorText("");

        // vsechno good
      
        let backendUrl = "";

        if (process.env.NODE_ENV === 'development') {
          backendUrl = 'http://localhost:1337'
        }
        
        if (process.env.NODE_ENV === 'production') {
          backendUrl = 'https://goodbye-backend.herokuapp.com'
        }

        // napojujeme se na API
        axios.post(`${backendUrl}/auth/local`, {
          identifier: 'app@goodbye.cz',
          password: 'Good.Bye123',
        }).then((res) => {
          const jwt = res.data.jwt;

          axios.delete(`${backendUrl}/comments/${id}`, {
            headers: {
              Authorization: `Bearer ${jwt}`
            },
          }).then((res) => {
            setPhase(4);
            setIsLoading(false);

            setTimeout(() => {
              setIsDisplayed(false);
            }, 2000);
          });
        });
      }

      else {
        setErrorText("Špatné heslo");
        setIsLoading(false);
      }
    }

    else {
      setErrorText("Toto pole je povinné");
      setIsLoading(false);
    }
  }

  const renderPhase = (phaseNumber) => {
    if (phaseNumber === 1) {
      return (
        <div className="post">
          <ReactMarkdown source={text} />
          <div className="bot">
            <div className="jmeno">{name} <span>{casoString}</span></div>
            <div className="buttons">
              <button onClick={() => setPhase(2)}>Nahlásit</button>
              <button onClick={() => setPhase(3)}>Smazat</button>
            </div>
          </div>
        </div>
      );
    }

    else if (phaseNumber === 2) {
      return (
        <div className="post">
          <h3>Opravdu chcete příspěvek nahlásit?</h3>
          <div className="tlacitka">
            <button className="tlacitko" onClick={() => nahlasit()}>
              {(isLoading) ? "Načítání..." : "Nahlásit"}
            </button>
            <button className="tlacitko" onClick={() => setPhase(1)}>Zpět</button>
          </div>
        </div>
      );
    }
    
    else if (phaseNumber === 3) {
      return (
        <div className="post">
          <h3>Zadejte heslo k příspěvku</h3>
          <form onSubmit={smazat}>
            <input type="password" value={pass} onChange={e => setPass(e.target.value)} placeholder="Heslo" />
            {errorText && <div className="errorMsg">{errorText}</div>}
            <div className="tlacitka">
              <input className="tlacitko" type="submit" value={(isLoading) ? "Načítání..." : "Smazat"} />
              <button className="tlacitko" onClick={() => setPhase(1)}>Zpět</button>
            </div>
          </form>
        </div>
      );
    }

    else if (phaseNumber === 4) {
      return (
        <div className="post">
          <div className="pridano">
            <img src={CheckCircle} alt="" />
            <span>Příspěvek smazán</span>
          </div>
        </div>
      );
    }

    else if (phaseNumber === 5) {
      return (
        <div className="post">
          <div className="pridano">
            <img src={CheckCircle} alt="" />
            <span>Nahlášeno</span>
          </div>
        </div>
      ); 
    }
  }



  return (
    <KomentarInner>
      {(isDisplayed) ? <>
        {renderPhase(phase)}  
      </> : null}
    </KomentarInner>
  );
}

export default KomentarAdd;
