import React from "react";
import { Router } from "@reach/router";
import Layout from "../components/Layout";

import ParteTemplate from "../templates/ParteTemplate";


const ParteRoot = () => {

	return (
		<Layout simpleHeader showPhone>
			<Router>
				<ParteTemplate path="/prt/:slug/" />
				<ParteTemplate shareModal path="/prt/:slug/sdilet/" />
				{/* <Upravit path="/prt/upravit/:slug/" /> */}
			</Router>
		</Layout>
	);
}

export default ParteRoot;
