import React, { useState } from "react";
import { navigate } from "gatsby";
import 'swiper/swiper-bundle.min.css';
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useCookies } from "react-cookie";
import Sha1 from "../../components/hash.js";

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import cs from "date-fns/locale/cs"; // the locale you want
registerLocale("cs", cs); // register it with the name you want



const EditAuthModalInner = styled.section`

`;



const EditAuthModal = ({ parteData }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const { register, handleSubmit, errors, watch, control } = useForm();
  const onSubmit = data => handleForm(data);
  const [cookies, setCookie, removeCookie] = useCookies(['session_id']);






  const handleForm = async (data) => {
    setIsLoading(true);







    if (parteData.password === Sha1.hash(data.password, {})) {
      setErrorMsg("");
      setCookie("session_id", parteData.id, { path: "/" });
      navigate(`/vytvorit-parte/upravit`);
    }

    else {
      setErrorMsg("Špatné heslo")
      setCookie("session_id", "", { path: "/" })
    }

    setIsLoading(false);
  }


  return (
    <EditAuthModalInner>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className={`pole ${errors.password && "inputError"}`}>
          <span>Zadejte heslo</span>

          <input
            ref={register({ required: true })}
            type="password"
            name="password"
          />
          
          {errors.password && <div className="errorMsg">Toto pole je povinné</div>}
          {errorMsg && <div className="errorMsg">{errorMsg}</div>}
        </label>

        <div className="buttons">
          <input className="submit" type="submit" value={(isLoading) ? "Načítání..." : "Potvrdit"} />
        </div>
      </form>
    </EditAuthModalInner>
  )
}


export default EditAuthModal;
