import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import 'swiper/swiper-bundle.min.css';
import CheckCircle from "../../assets/img/success.svg";
import axios from "axios";
import Sha1 from "../../components/hash.js";



const KomentarInner = styled.section`
  .input {
    background: #FFFFFF;
    border: 1px solid #C8C8C8;
    border-radius: 5px;
    padding: 30px;

    @media (max-width: 850px) {
      padding: 20px;
    }

    textarea {
      width: 100%;
      resize: none;
      height: 150px;
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #000000;
      line-height: 25px;
      border: 0;
      border-bottom: 1px solid #D8D8D8;
      margin-bottom: -15px;
    }

    .errorMsgPrvni {
      font-family: Visuelt-Regular;
      line-height: 25px;
      font-size: 16px;
      color: #FF6060;
      margin: 0;
      margin-top: 20px;
    }

    .buttons {
      display: block;
      margin-top: 30px;

      .submit {
        font-family: Visuelt-Medium;
        font-size: 16px;
        color: #FFFFFF;
        text-align: center;
        background: #3A557C;
        border: 0;
        padding: 10px 0;
        width: 120px;
        border-radius: 5px;

        @media (max-width: 359px) {
          width: 110px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .zpet {
        font-family: Visuelt-Medium;
        font-size: 16px;
        color: #000;
        background: #efefef;
        text-align: center;
        border: 0;
        padding: 10px 0;
        width: 120px;
        border-radius: 5px;
        margin-left: 20px;

        @media (max-width: 359px) {
          width: 90px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    label {
      display: block;
      margin-bottom: 20px;

      span {
        display: block;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #6C6C6C;
        line-height: 25px;
        margin-bottom: 8px;
      }

      input {
        display: block;
        width: calc(100% - 24px);
        max-width: 300px;
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        padding: 10px;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        line-height: 25px;

        &:focus {
          border: 2px solid #566FEF;
        }
      }

      .errorMsg {
        font-family: Visuelt-Regular;
        line-height: 25px;
        font-size: 16px;
        color: #FF6060;
        margin: 0;
        margin-top: 8px;
      }
    }

    .inputError {
      input {
        border: 2px solid #FF6060 !important;
      }

      span {
        color: #FF6060;
      }
    }

    .pridano {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        margin-right: 20px;
      }

      span {
        font-family: Visuelt-Medium;
        font-size: 18px;
        color: #000;
      }
    }
  }
`;

const KomentarAdd = ({ slug }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState("");
  const [errorText, setErrorText] = useState("");
  const [phase, setPhase] = useState(1);
  const { register, handleSubmit, errors, watch } = useForm();
  const onSubmit = data => handleForm(data);
  const pass1 = useRef({});
  pass1.current = watch("pass1", "");



  const handleForm = (data) => {
    setIsLoading(true);

    const komentarData = {
      "text": text,
      "time": Date.now(),
      "slug": slug,
      "name": data.name,
      "password": Sha1.hash(data.pass2, {})
    }
    
    
    // máme tady vars: data a text
    // odeslat to POST do databáze
   
    let backendUrl = "";

    if (process.env.NODE_ENV === 'development') {
      backendUrl = 'http://localhost:1337'
    }
    
    if (process.env.NODE_ENV === 'production') {
      backendUrl = 'https://goodbye-backend.herokuapp.com'
    }

    // napojujeme se na API
    axios.post(`${backendUrl}/auth/local`, {
      identifier: 'app@goodbye.cz',
      password: 'Good.Bye123',
    }).then((res) => {
      const jwt = res.data.jwt;

      axios.post(`${backendUrl}/comments`, komentarData, {
        headers: {
          Authorization: `Bearer ${jwt}`
        },
      }).then((res) => {
        nextPhase(3);
      });
    });
  }


  const nextPhase = (number) => {
    if (number === 1) {
      
    }

    else if (number === 2) {
      if (text) {
        setErrorText("");
        setPhase(2);
      }

      else {
        setErrorText("Toto pole je povinné");
      }
    }

    else if (number === 3) {
      setIsLoading(false);
      setPhase(3);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }


  const renderPhase = (phaseNumber) => {
    if (phaseNumber === 1) {
      return (
        <>
          <textarea value={text} onChange={e => setText(e.target.value)} placeholder="Napiště nějakou zprávu, vzpomínku…" />
          {errorText && <div className="errorMsgPrvni">{errorText}</div>}
          <div className="buttons">
            <button className="submit" onClick={() => nextPhase(2)}>Přidat</button>
          </div>
        </>
      );
    }

    else if (phaseNumber === 2) {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className={`${errors.name && "inputError"}`}>
            <span>Vaše jméno</span>
            <input ref={register({ required: true })} type="text" name="name"/>
            {errors.name && <div className="errorMsg">Toto pole je povinné</div>}
          </label>

          <label className={`${errors.pass1 && "inputError"}`}>
            <span>Zadat heslo</span>
            <input ref={register({ required: true })} type="password" name="pass1"/>
            {errors.pass1 && <div className="errorMsg">Toto pole je povinné</div>}
          </label>

          <label className={`${errors.pass2 && "inputError"}`}>
            <span>Zoopakovat heslo</span>
            <input ref={register({
              validate: value => value === pass1.current || "Hesla se neshodují"
            })} type="password" name="pass2"/>

            {errors.pass2 && <div className="errorMsg">Hesla se neshodují</div>}
          </label>

          <div className="buttons">
            <input className="submit" type="submit" value={(isLoading) ? "Načítání..." : "Pokračovat"} />
            <button className="zpet" onClick={() => setPhase(1)}>Zpět</button>
          </div>
        </form>
      );
    }

    else if (phaseNumber === 3) {
      return (
        <div className="pridano">
          <img src={CheckCircle} alt="" />
          <span>Přidáno</span>
        </div>
      );
    }

  }

  return (
    <KomentarInner>
      <div className="input">
        {renderPhase(phase)}
      </div>
    </KomentarInner>
  );
}

export default KomentarAdd;
